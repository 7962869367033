import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'; // Import the GA library

import Validator from './components/Validator';

function App() {
    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize('G-79X1DX0L6J'); // Replace with your GA tracking ID

        // Track the first page load
        ReactGA.send("pageview");

        // Optionally, you can track page changes using the history object if using React Router
        // history.listen((location) => {
        //     ReactGA.set({ page: location.pathname });
        //     ReactGA.send("pageview");
        // });
    }, []);

    return (
        <div className="App">
            <Validator />
        </div>
    );
}

export default App;
