import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import '../css/validator.css';
import yaml from 'js-yaml';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';

const Validator = () => {
    const [inputText, setInputText] = useState('');
    const [message, setMessage] = useState({ text: '', type: '' });  // Updated state to include type
    const [isPretty, setIsPretty] = useState(true);
    const [isValidating, setIsValidating] = useState(false);
    const [isFormatting, setIsFormatting] = useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [theme, setTheme] = useState('vs-light');

    // Handle JSON validation
    const handleValidate = () => {
        if (!inputText.trim()) {
            setMessage({ text: 'Error: Input is empty. Please enter JSON data.', type: 'error' });
            return;
        }

        setIsValidating(true);
        setMessage({ text: '', type: '' });

        setTimeout(() => {
            try {
                JSON.parse(inputText);
                setMessage({ text: 'Valid JSON!', type: 'success' });  // If valid, set success type
            } catch (error) {
                setMessage({ text: `Error: Invalid JSON. ${error.message}`, type: 'error' });
            } finally {
                setIsValidating(false);
            }
        }, 1500);
    };

    // Handle pretty or compressed JSON formatting
    const handlePrettyCompress = () => {
        if (!inputText.trim()) {
            setMessage({ text: 'Error: Input is empty. Please enter JSON data.', type: 'error' });
            return;
        }

        setIsFormatting(true);
        setMessage({ text: '', type: '' });

        setTimeout(() => {
            try {
                const parsedJson = JSON.parse(inputText);
                const formattedJson = isPretty
                    ? JSON.stringify(parsedJson, null, 2)
                    : JSON.stringify(parsedJson);
                setInputText(formattedJson);
                setIsPretty(!isPretty);
            } catch (error) {
                setMessage({ text: `Error: Invalid JSON. ${error.message}`, type: 'error' });
            } finally {
                setIsFormatting(false);
            }
        }, 1500);
    };

    // Clear the input and reset message
    const handleClear = () => {
        setInputText('');
        setMessage({ text: '', type: '' });
        setIsPretty(true);
    };

    // Copy to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(inputText)
            .then(() => setMessage({ text: 'Copied to clipboard!', type: 'success' }))
            .catch(() => setMessage({ text: 'Error: Unable to copy to clipboard.', type: 'error' }));
    };

    // Paste from clipboard
    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setInputText(text);
        } catch (error) {
            setMessage({ text: 'Error: Unable to paste from clipboard.', type: 'error' });
        }
    };

    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => setInputText(e.target.result);
            reader.onerror = () => setMessage({ text: 'Error: Unable to read the file.', type: 'error' });
            reader.readAsText(file);
        }
    };

    // Convert JSON to CSV
    const convertJsonToCsv = (jsonData) => {
        const flattenObject = (obj, prefix = '') => {
            return Object.keys(obj).reduce((acc, key) => {
                const value = obj[key];
                const newKey = prefix ? `${prefix}.${key}` : key;
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    Object.assign(acc, flattenObject(value, newKey));
                } else {
                    acc[newKey] = value;
                }
                return acc;
            }, {});
        };

        const jsonArray = Array.isArray(jsonData) ? jsonData : [jsonData];
        const flattenedArray = jsonArray.map(flattenObject);
        const csvHeaders = Array.from(new Set(flattenedArray.flatMap(obj => Object.keys(obj))));
        const csvRows = flattenedArray.map(row => csvHeaders.map(field => JSON.stringify(row[field] || '')).join(','));

        return [csvHeaders.join(','), ...csvRows].join('\n');
    };

    // Export JSON to various formats
    const exportJson = (format) => {
        if (!inputText.trim()) {
            alert('Error: No JSON to export.');
            return;
        }

        try {
            const parsedJson = JSON.parse(inputText);
            let exportData, fileType, fileName;

            switch (format) {
                case 'json':
                    exportData = JSON.stringify(parsedJson, null, 2);
                    fileType = 'application/json';
                    fileName = 'exported.json';
                    break;
                case 'csv':
                    exportData = convertJsonToCsv(parsedJson);
                    fileType = 'text/csv';
                    fileName = 'exported.csv';
                    break;
                case 'xml':
                    exportData = `<root>${JSON.stringify(parsedJson)}</root>`;
                    fileType = 'application/xml';
                    fileName = 'exported.xml';
                    break;
                case 'yaml':
                    exportData = yaml.dump(parsedJson);
                    fileType = 'application/x-yaml';
                    fileName = 'exported.yaml';
                    break;
                case 'xlsx':
                    const ws = XLSX.utils.json_to_sheet(parsedJson);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                    XLSX.writeFile(wb, 'exported.xlsx');
                    return;
                case 'pdf':
                    exportToPDF(parsedJson);
                    return;
                default:
                    return;
            }

            const blob = new Blob([exportData], { type: fileType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            alert('Error: Invalid JSON. Cannot export.');
        }
    };

    // Export JSON to PDF
    const exportToPDF = (jsonData) => {
        const doc = new jsPDF();
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        doc.text(JSON.stringify(jsonData, null, 2), 10, 10);
        doc.save('exported.pdf');
    };

    // Toggle between themes
    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'vs-dark' ? 'vs-light' : 'vs-dark'));
    };

    // Toggle visibility of advanced options
    const toggleAdvancedOptions = () => {
        setShowAdvancedOptions(prev => !prev);
    };

    // Fetch JSON from a URL
    const handleBrowse = async () => {
        const url = prompt('Enter the URL to fetch JSON from:');
        if (url) {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error('Network response was not ok');
                const jsonData = await response.json();
                setInputText(JSON.stringify(jsonData, null, 2));
                setMessage({ text: '', type: '' });
            } catch (error) {
                setMessage({ text: `Error fetching JSON: ${error.message}`, type: 'error' });
            }
        }
    };

    return (
        <div className="validator-container">
            {showAdvancedOptions && (
                <div className="advanced-options-buttons">
                    <div className="theme-toggle-container">
                        <label className="switch">
                            <input type="checkbox" onChange={toggleTheme} />
                            <span className="slider"></span>
                        </label>
                        <span className="theme-toggle-text">Toggle Theme</span>
                    </div>
                    <div className="upload-browse-container">
                        <label htmlFor="file-upload" className="upload-button" title="Upload a JSON file">Upload</label>
                        <input 
                            type="file" 
                            accept="application/json" 
                            onChange={handleFileUpload} 
                            style={{ display: 'none' }} 
                            id="file-upload"
                        />
                        <button className="browse-button" onClick={handleBrowse} title="Fetch JSON from a URL">Browse</button>
                        <div className="export-dropdown">
                            <label>Export As: </label>
                            <select onChange={(e) => exportJson(e.target.value)} title="Select format to export JSON">
                                <option value="">Select Format</option>
                                <option value="json">JSON</option>
                                <option value="csv">CSV</option>
                                <option value="xml">XML</option>
                                <option value="yaml">YAML</option>
                                <option value="xlsx">XLSX</option>
                                <option value="pdf">PDF</option>
                            </select>
                        </div>
                    </div>
                </div>
            )}

            <div className="textarea-container">
                {!showAdvancedOptions && (
                    <div className="info-message">
                        To format and validate your JSON, just copy + paste it below:
                    </div>
                )}
                <Editor
                    height="50vh"
                    defaultLanguage="json"
                    value={inputText}
                    theme={theme}
                    onChange={value => setInputText(value || '')}
                />
            </div>

            <div className="button-group">
                <div className="left-buttons">
                    <button className="validate-button" onClick={handleValidate} disabled={isValidating} title="Validate the JSON syntax">
                        {isValidating ? 'Validating...' : 'Validate'}
                    </button>
                    <button className="pretty-button" onClick={handlePrettyCompress} disabled={isFormatting} title="Format JSON for better readability">
                        {isFormatting ? 'Formatting...' : isPretty ? 'Pretty' : 'Compress'}
                    </button>
                    <button className="advanced-options-button" onClick={toggleAdvancedOptions} title="Toggle advanced options">
                        {showAdvancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
                    </button>
                </div>

                <div className="right-buttons">
                    <button className="clear-button" onClick={handleClear} title="Clear the input">Clear</button>
                    <button className="copy-button" onClick={handleCopy} title="Copy JSON to clipboard">Copy</button>
                    <button className="paste-button" onClick={handlePaste} title="Paste JSON from clipboard">Paste</button>
                </div>
            </div>

            {message.text && (
                <div className={`message ${message.type === 'error' ? 'error-message' : 'success-message'}`}>
                    {message.text}
                </div>
            )}
        </div>
    );
};

export default Validator;
